<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col>
        <v-encounter-bar v-model="currentEncounterId" @encounterLoaded="onEncounterLoaded" @encounterUnloaded="onEncounterUnloaded"></v-encounter-bar>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col v-if="currentEncounter">
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab key="medicalRecords">เวชระเบียน</v-tab>
            <v-tab key="diagnoses">การวินิจฉัย</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="medicalRecords">
              <v-document-list :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" folder="default"></v-document-list>
            </v-tab-item>
            <v-tab-item key="diagnoses">
              <v-container fluid>
                <v-row dense>
                  <v-col>
                    <v-model-table model-name="Encounters" model-title="การวินิจฉัยโรค"
                      :model-api="['models','Registration','EncountersDiagnoses']"
                      :model-api-search="diagnosisInitialData"
                      api-order-by="diagnosisType"
                      :headers="diagnosisHeaders"
                      :initial-data="diagnosisInitialData"
                      ref="diagnosisTable"
                    >
                      <template v-slot:modelForm="{data,rules,isCreating}">
                        <v-container pa-0 fluid>
                          <v-row>
                            <v-col cols="12" md="2">
                              <v-select
                                label="ชนิดการวินิจฉัย"
                                :rules="[rules.require()]"
                                :items="[
                                  {text: 'Primary', value: 'primary'},
                                  {text: 'Comorbidity', value: 'comorbid'},
                                  {text: 'Complication', value: 'complication'},
                                  {text: 'Others', value: 'others'},
                                  {text: 'External cause', value: 'external'},
                                ]"
                                v-model="data.diagnosisType"
                                item-text="text"
                                item-value="value"
                              >
                              </v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-master-field :rules="[rules.require()]" v-model="data.icd10" label="รหัส ICD-10"
                                groupKey="$ICD10"
                                clearable show-code
                              ></v-master-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field v-model="data.diagnosisText" label="การวินิจฉัย"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </template>
                      <template v-slot:item.icd10desc="props">
                        <v-label-master group-key="$ICD10" :item-code="props.item.icd10"></v-label-master>
                      </template>
                    </v-model-table>
                  </v-col>
                  <v-col>
                    <v-model-table model-name="PatientsDiagnoses" model-title="ประวัติการวินิจฉัย"
                      :model-api="['models','Patient','PatientsDiagnoses']"
                      :model-api-search="diagnosisHistorySearch"
                      api-order-by="diagnosisType"
                      :headers="diagnosisHistoryHeaders"
                      readonly
                    >
                      <template v-slot:item.icd10desc="props">
                        <v-label-master group-key="$ICD10" :item-code="props.item.icd10"></v-label-master>
                      </template>
                      <template v-slot:item.action="props">
                        <v-btn color="primary" @click="addDiagnosis(props.item.icd10,props.item.diagnosisType)">เพิ่ม</v-btn>
                      </template>
                    </v-model-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
      <v-col v-else>
        <v-model-table model-name="Encounters" model-title="รายการการตรวจผู้ป่วยนอก"
          :model-api="['models','Registration','Encounters']" model-key="encounterId"
          api-order-by="admitDateTime,desc"
          api-with="diagnoses"
          :model-api-search="opdEncounterQuery"
          :headers="headers"
          :insertable="false"
          server-pagination
        >
          <template v-slot:toolBarItem>
            <v-date-field
              v-model="currentAdmitDate"
              label="วันที่รับบริการ"
              class="pr-1" clearable
              single-line hide-details
            ></v-date-field>
            <v-master-field v-model="currentClinic" :custom-api="['models','Master','Clinics','query']"
              custom-api-text="clinicName" custom-api-value="clinicCode"
              customApiEagerLoad
              class="pr-1" clearable
              label="คลินิก" 
              single-line hide-details
              show-code></v-master-field>
            <v-checkbox v-model="currentNoPrimaryDiagnosis" label="ไม่มีการวินิจฉัยหลัก" single-line hide-details></v-checkbox>
          </template>
          <template v-slot:toolBarSearch>
            <v-text-field class="pr-2" v-model="currentFilter"
              label="HN" 
              append-icon="search"
              single-line hide-details
            ></v-text-field>
          </template>
          <template v-slot:item.encounterId="props">
            <v-icon v-if="props.item.fromAppointmentId" color="green darken-3">mdi-calendar-check</v-icon>
            <v-icon v-else color="red">mdi-calendar-remove</v-icon>
            <label class="font-weight-bold">{{ props.item.encounterId.slice(-3) }}</label>
          </template>
          <template v-slot:item.patient.name_th="props">
            <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
          </template>
          <template v-slot:item.admitDateTime="props">
            <v-label-datetime :date-time="props.item.admitDateTime" short-date></v-label-datetime>
          </template>
          <template v-slot:item.diagnoses="props">
            <v-chip class="mr-1" :color="chipColor[diagnosis.diagnosisType]" v-for="diagnosis in props.item.diagnoses" :key="diagnosis.id">{{ diagnosis.icd10 }}</v-chip>
          </template>
          <template v-slot:item.action="props">
            <v-btn color="primary" @click="currentEncounterId=props.item.encounterId">เปิด</v-btn>
          </template>
        </v-model-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import encounterLoader from '@/components/mixins/encounterLoader'
import helperDateTime from 'tantee-common/lib/helperDateTime'
import {isUndefined} from 'lodash'

export default {
  mixins: [encounterLoader],
  data: ()=>({
    currentEncounterId: undefined,
    currentAdmitDate: undefined,
    currentClinic: undefined,
    currentNoPrimaryDiagnosis: false,
    headers: [
      {
        text: 'เวลาเข้าตรวจ',
        value: 'admitDateTime',
        class: 'body-2'
      },
      {
        text: 'เลขรับบริการ',
        value: 'encounterId',
        class: 'body-2'
      },
      {
        text: 'HN',
        value: 'hn',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'patient.name_th',
        class: 'body-2'
      },
      {
        text: 'คลินิก',
        value: 'clinic.clinicName',
        class: 'body-2'
      },
      {
        text: 'แพทย์',
        value: 'doctor.nameTH',
        class: 'body-2'
      },
      {
        text: 'การวินิจฉัย',
        value: 'diagnoses',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '200px',
        sortable: false,
        class: 'body-2'
      }
    ],
    diagnosisHeaders: [
      {
        text: 'ชนิดการวินิจฉัย',
        value: 'diagnosisType',
        class: 'body-2',
        width: '150px'
      },
      {
        text: 'ICD-10',
        value: 'icd10',
        class: 'body-2',
        width: '100px'
      },
      {
        text: 'ICD-10 Description',
        value: 'icd10desc',
        class: 'body-2'
      },
      {
        text: 'diagnosisText',
        value: 'diagnosisText',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    diagnosisHistoryHeaders: [
      {
        text: 'ชนิดการวินิจฉัย',
        value: 'diagnosisType',
        class: 'body-2',
        width: '150px'
      },
      {
        text: 'ICD-10',
        value: 'icd10',
        class: 'body-2',
        width: '100px'
      },
      {
        text: 'ICD-10 Description',
        value: 'icd10desc',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '100px',
        sortable: false,
        class: 'body-2'
      }
    ],
    chipColor: {
      primary: 'primary',
      comorbid: 'light-blue lighten-2',
      complication: 'red lighten-2',
      others: 'green lighten-2',
      external: 'orange lighten-2',
    }
  }),
  computed: {
    opdEncounterQuery() {
      let result = {
        encounterType: 'AMB',
      }

      if (this.currentAdmitDate) result['whereDate#admitDateTime'] = this.currentAdmitDate
      if (this.currentClinic) result['clinicCode'] = this.currentClinic
      if (this.currentNoPrimaryDiagnosis) result['doesntHave#primaryDiagnosis'] = true
      if (this.currentFilter) result['where#hn#like'] = this.currentFilter+"%" 

      return result
    },
    diagnosisInitialData() {
      let result = {
        hn: this.currentEncounter.hn,
        encounterId: this.currentEncounter.encounterId,
      }
      return result
    },
    diagnosisHistorySearch() {
      let result = {
        hn: this.currentEncounter.hn,
      }
      return result
    },
  },
  methods: {
    addDiagnosis(icd10,diagnosisType="primary") {
      this.$refs.diagnosisTable.newData({icd10: icd10, diagnosisType: diagnosisType})
    }
  }
}
</script>

<style lang="css" scoped>
</style>
